// src/components/certifications/Certifications.jsx
import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardMedia, Chip, Divider } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const CertificationCard = ({ certification }) => {
  return (
    <Card 
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <CardMedia
        component="div"
        sx={{
          height: 140,
          bgcolor: certification.bgColor || 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" color="white" fontWeight="bold" align="center" sx={{ p: 2 }}>
          {certification.name}
        </Typography>
      </CardMedia>
      <CardContent sx={{ flexGrow: 1, pt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <VerifiedIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="subtitle1" component="div" fontWeight="medium">
            {certification.code}
          </Typography>
        </Box>
        
        <Typography variant="body2" color="text.secondary" paragraph>
          {certification.description}
        </Typography>
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
          {certification.skills.map((skill, index) => (
            <Chip 
              key={index}
              label={skill}
              size="small"
              sx={{ 
                bgcolor: 'background.paper', 
                border: '1px solid',
                borderColor: 'primary.light'
              }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const Certifications = () => {
  const certifications = [
    {
      name: "Azure Solutions Architect Expert",
      code: "AZ-305",
      bgColor: "#0078d4",
      description: "Validates expertise in designing cloud and hybrid solutions that run on Azure, including compute, network, storage, and security.",
      skills: ["Solution Architecture", "Identity & Governance", "Business Continuity", "Data Storage", "Infrastructure"]
    },
    {
      name: "DevOps Engineer Expert",
      code: "AZ-400",
      bgColor: "#7FBA00",
      description: "Demonstrates ability to combine people, processes, and technologies to continuously deliver valuable products and services.",
      skills: ["CI/CD Pipelines", "Source Control", "Agile Development", "Infrastructure as Code", "Monitoring"]
    },
    {
      name: "Azure Security Engineer Associate",
      code: "AZ-500",
      bgColor: "#d13438",
      description: "Validates skills in implementing security controls and threat protection, managing identity and access, and securing data, applications, and networks.",
      skills: ["Identity Management", "Platform Protection", "Security Operations", "Data Protection", "Threat Management"]
    },
    {
      name: "Azure Administrator Associate",
      code: "AZ-104",
      bgColor: "#0078d4",
      description: "Validates expertise in implementing, managing, and monitoring an organization's Microsoft Azure environment, including virtual networks, storage, compute, identity, security, and governance.",
      skills: ["Azure Administration", "Virtual Networks", "Storage Management", "Azure Compute", "Identity & Access"]
    },
    {
      name: "AI Engineer Associate",
      code: "AI-102-IP",
      bgColor: "#7719aa",
      description: "Demonstrates ability to design and implement AI solutions involving natural language processing, speech, computer vision, and conversational AI.",
      skills: ["Azure AI Services", "Machine Learning", "Cognitive Services", "Bot Framework", "AI Solution Design"]
    },
    {
      name: "Azure Developer Associate",
      code: "AZ-204-IP",
      bgColor: "#0078d4",
      description: "Validates ability to design, build, test, and maintain cloud applications and services on Microsoft Azure, including implementing Azure compute, storage, security, and monitoring.",
      skills: ["Cloud Development", "API Integration", "Azure SDKs", "Serverless Computing", "Azure Storage"]
    },
    {
      name: "AWS Solutions Architect Associate",
      code: "SAA-C02",
      bgColor: "#FF9900",
      description: "Validates expertise in designing available, cost-efficient, fault-tolerant systems on AWS, including compute, networking, storage, and database services.",
      skills: ["AWS Architecture", "EC2 & VPC", "S3 & Glacier", "RDS & DynamoDB", "IAM & Security"]
    },
    {
      name: "CompTIA Security+",
      code: "SY0-601",
      bgColor: "#5FAD4E",
      description: "Establishes the core knowledge required of any cybersecurity role and provides a springboard to intermediate-level cybersecurity jobs.",
      skills: ["Threats & Vulnerabilities", "Risk Management", "Cryptography", "Network Security", "Access Control"]
    },
    {
      name: "CompTIA Linux+",
      code: "XK0-004",
      bgColor: "#5FAD4E",
      description: "Validates the core foundational skills and knowledge required of Linux system administrators, including installation, configuration, operation, and troubleshooting.",
      skills: ["Linux Administration", "Shell Scripting", "Security", "Networking", "System Management"]
    },
    {
      name: "Red Hat Ansible Automation",
      code: "EX294-IP",
      bgColor: "#EE0000",
      description: "In Progress: Validates skills in automating system administration tasks with Ansible, including installation, configuration, and implementation.",
      skills: ["Automation", "Configuration Management", "Playbooks", "Inventory Management", "Role Development"]
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" fontWeight="bold">
          Professional Certifications
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph color="text.secondary" sx={{ mb: 5 }}>
          Industry-recognized credentials validating my expertise in cloud technologies and IT security
        </Typography>
        
        <Grid container spacing={4}>
          {certifications.map((cert, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CertificationCard certification={cert} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Certifications;